import React from 'react'
import Banner from './Banner'
import ContentArea from './ContentArea'

import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Content = () => {
    const param = useParams()
    const keywords = param.keywords

    const [bixs, setBixs] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const [offset, setOffset] = useState(0)

    const [myLat, setMyLat] = useState(null)
    const [myLong, setMyLong] = useState(null)

    const nextFun = (e) =>{
        setOffset(offset+12)
    }

    const prevFun = (e) =>{
        setOffset(offset-12)
    }

    useEffect(()=>{
        
        const showPosition = () => {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    setMyLat(position.coords.latitude)
                    setMyLong(position.coords.longitude)
                });
            } else {
                alert("Sorry, your browser does not support HTML5 geolocation.");
            }
        }
        showPosition()

        fetch(`https://mob.eazylocate.com/api/business/search.php?keyword=${keywords}&offset=${offset}&lat=${myLat}&log=${myLong}`)
        .then(res=>res.json())
        .then((data)=>{

            const sorted = data.location.sort((a,b)=>a.distance-b.distance)
            const chunk = sorted.slice(offset, offset + 12);
            setBixs(chunk)
            setIsPending(false)
        })
        
    }, [keywords, offset, myLat, myLong])

    return (
        <div>
            <Banner />
            { 
                isPending && 
                (<div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                        <div className="mx-auto my-auto p-5" >
                            <img src="../assets/images/loading.gif" alt="loading" />
                        </div>
                    </div>
                </div>
                )
                
            }
            { bixs && <ContentArea bixs={bixs} />}
            {
                bixs && 

                <div className="container">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                            <div className="d-flex justify-content-between">
                                <button className="site-button btn-lg" onClick={ prevFun }><i className="fa fa-arrow-left"> Prev</i></button>
                                <button className="site-button ml-auto" onClick={ nextFun }>Next <i className="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Content