import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Categories from "./pages/categories";
import Listings from "./pages/listings";
import AddList from "./pages/add_list";
import Category from "./pages/category";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacyPolicy";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ListDetails from "./pages/listDetails";
import Search from "./pages/search";

function App() {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/listing" element={<Listings />} />
                <Route path="/list_details/:id" element={<ListDetails />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/category/:category" element={<Category />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/add_list" element={<AddList />} />
                <Route path="/search/:keywords" element={<Search />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
