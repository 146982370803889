import React from 'react'
import Banner from './Banner'
import ContentArea from './ContentArea'

const Content = () => {
    return (
        <div className="page-wrapper">
            <div className="page-content bg-white">
                <Banner />
                <ContentArea />
            </div>
        </div>
    )
}

export default Content