import Content from "../components/home/Content"

const Home = () => {
  return (
    <div>
        <Content />
    </div>
  )
}

export default Home