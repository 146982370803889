import React from 'react'
import Banner from './Banner'
import ContentBlock from './ContentBlock'

const Content = () => {
  return (
    <div className="page-content bg-white">
        <Banner />
        <ContentBlock />
    </div>
  )
}

export default Content