import React from "react";

const ContentArea = () => {
    return (
        <div className="row">
            <div className="col-lg-12 mb-4 col-sm-12">
                <div className="clearfix contact-form m-b30">
                    <div className="section-head text-black">
                        <h2 className="box-title">Privacy Policy</h2>
                        <div className="dlab-separator bg-primary"></div>
                        <h4>Effective Date: 21 October 2022</h4>
                        <p>
                            Thank you for using our search engine applications
                            -Eazy movement . We are committed to protecting your
                            privacy and maintaining the confidentiality of your
                            personal information. This Privacy Policy outlines
                            how we collect, use, disclose, and safeguard your
                            information when you use our Website. By accessing
                            or using our Website, you consent to the terms of
                            this Privacy Policy.
                        </p>
                        <h4>Information We Collect</h4>
                        <p>
                            We may collect both personal and non-personal
                            information when you use our Website. Personal
                            information refers to data that can be used to
                            identify you personally, such as your name, email
                            address, or phone number. Non-personal information
                            is data that does not directly identify you and is
                            collected automatically, such as your IP address,
                            browser type, or device information.
                        </p>
                        <h4>Collection of Information</h4>
                        <p>
                            We collect information in the following ways: <br />
                            <strong>2.1 Information You Provide</strong> <br />
                            When you voluntarily provide us with personal
                            information through forms on our Website or through
                            other means of communication, we collect and store
                            that information. This may include your name, email
                            address, or any other information you choose to
                            provide.
                        </p>
                        <p>
                            We collect information in the following ways: <br />
                            <strong>
                                2.2 Information Collected Automatically
                            </strong>{" "}
                            <br />
                            We may automatically collect certain information
                            about your usage of our Website through cookies, log
                            files, and other similar technologies. This
                            information may include your IP address, browser
                            type, operating system, referring URLs, pages
                            viewed, links clicked, and the dates and times of
                            your visits.
                        </p>
                        <h4>Use of Information</h4>
                        <p>
                            <b>3.1 To Provide and Improve Our Services</b>
                            <br /> We use your information to operate, maintain,
                            and improve our Website and the services we offer.
                            This includes personalizing your experience,
                            understanding how our users interact with our
                            Website, and making enhancements based on user
                            feedback.
                        </p>
                        <p>
                            <b>3.2 To Communicate with You</b>
                            <br /> We may use your email address or other
                            contact information to respond to your inquiries,
                            provide information about our services, or send you
                            administrative notifications.
                        </p>
                        <p>
                            <b>3.3 To Analyze and Aggregate Data</b>
                            <br /> We may anonymize and aggregate the
                            information we collect to generate statistics and
                            conduct research. This helps us understand trends,
                            track user preferences, and improve our services.
                            Aggregated data does not identify any individual and
                            cannot be linked back to you.
                        </p>
                        <h4>Disclosure of Information</h4>
                        <p>
                            We do not sell, trade, or rent your personal
                            information to third parties. However, we may share
                            your information in the following circumstances:
                            <br />
                            <b>4.1 Service Providers</b> We may engage
                            third-party service providers who assist us in
                            operating our Website or providing services to you.
                            These service providers have access to your
                            information only to perform specific tasks on our
                            behalf and are obligated to keep your information
                            confidential.
                        </p>
                        <p>
                            <b>4.2 Legal Requirements</b> We may disclose your
                            information if required to do so by law or if we
                            believe that such action is necessary to comply with
                            a legal obligation, protect our rights or the rights
                            of others, investigate fraud, or ensure the safety
                            of our users.
                        </p>
                        <p>
                            <b>4.3 Business Transfers </b>
                            In the event of a merger, acquisition, or sale of
                            our business or assets, your information may be
                            transferred to a new entity as part of the
                            transaction. We will notify you via email or a
                            prominent notice on our Website if such a transfer
                            occurs and your information becomes subject to a
                            different privacy policy.
                        </p>
                        <p>
                            <b>4.3 Business Transfers </b>
                            In the event of a merger, acquisition, or sale of
                            our business or assets, your information may be
                            transferred to a new entity as part of the
                            transaction. We will notify you via email or a
                            prominent notice on our Website if such a transfer
                            occurs and your information becomes subject to a
                            different privacy policy.
                        </p>
                        <h4>Data Security</h4>
                        <p>
                            We employ industry-standard security measures to
                            safeguard your information from unauthorized access,
                            disclosure, alteration, or destruction. However,
                            please note that no method of transmission over the
                            internet or electronic storage is 100% secure, and
                            we cannot guarantee absolute security.
                        </p>
                        <h4>Third-Party Links</h4>
                        <p>
                            Our Website may contain links to third-party
                            websites or services that are not owned or
                            controlled by us. This Privacy Policy does not apply
                            to such third-party websites or services, and we are
                            not responsible for their privacy practices. We
                            encourage you to review the privacy policies
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentArea;
