import React from 'react'
import Content from '../components/contact/Content'

const Contact = () => {
    return (
      <div>
        <Content />
      </div>
    )
}

export default Contact