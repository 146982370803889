import React from 'react'

const ImageSection = () => {
    return (
        <div className="col-xl-4 col-lg-5 col-md-12 m-b30 sticky-top">
            <aside className="side-bar listing-side-bar">
                <div className="content-box"><div className="content-header"><h3 className="title">Upload Image</h3></div>
                    <div className="content-body">
                        <img className="img-cover" src="assets/images/add/add1.jpg" alt="" />
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default ImageSection