import React from 'react'
import Instruction from './Instruction'
import Places from './Places'

const ContentBlock = () => {
  return (
    <div className="content-block">
        <Places />
        <Instruction />
    </div>
  )
}

export default ContentBlock