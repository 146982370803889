import React from 'react'

const ContactForm = () => {
  return (
    <div className="row">
		<div className="col-lg-6 mb-4 mb-md-0">
			<div className="clearfix contact-form m-b30">
				<div className="section-head text-black">
					<h2 className="box-title">Get In Touch</h2>
					<div className="dlab-separator bg-primary"></div>
				</div>
				<div className="dzFormMsg"></div>
				<form method="post" className="dzForm" action="script/contact.php">
					<input type="hidden" name="dzToDo" value="Contact" />
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<div className="input-group">
									<input name="dzName" type="text" required="" className="form-control" placeholder="Your Name" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<div className="input-group">
									<input name="dzEmail" type="email" className="form-control" required="" placeholder="Your Email Id" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<div className="input-group">
									<input name="dzOther[Phone]" type="text" required="" className="form-control" placeholder="Phone" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<div className="input-group">
									<input name="dzOther[Subject]" type="text" required="" className="form-control" placeholder="Subject" />
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<div className="input-group">
									<textarea name="dzMessage" rows="4" className="form-control" required="" placeholder="Your Message..."></textarea>
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<div className="recaptcha-bx">
								<div className="input-group">
									<div className="g-recaptcha" data-sitekey="<!-- Put reCaptcha Site Key -->" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
									<input className="form-control d-none" data-recaptcha="true" required="" data-error="Please complete the Captcha" style={{display: 'none'}} />
								</div>
							</div>
						</div>
						<div className="col-md-12"><button name="submit" type="submit" value="Submit" className="site-button button-lg radius-xl">SUBMIT NOW</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div className="col-lg-6 d-flex">
			<div id="map3" className="m-b30 align-self-stretch" style={{width:'100%', minHeight: '300px'}}></div>
		</div>
	</div>
  )
}

export default ContactForm