import React from 'react'

const MapInfo = ({business, id}) => {
    return (
        business.map((buss)=>(
            <div className="col-xl-4 col-lg-5 col-md-12 sticky-top p-b30" key={id}>
                <aside className="side-bar listing-side-bar">
                    <div className="widget widget_map">
                        <img src={"https://eazylocate.com/"+buss.image} alt="business " className="m-b30 align-self-stretch" style={{ width: '100%', height: '400px'}} />
                        <a href={"https://www.google.com/maps/search/?api=1&query="+buss.geocode} target="_blank" rel="noreferrer" className="site-button button-lg radius-xl m-b30 text-uppercase"><i className="la la-map"></i> get directions</a>
                    </div>
                </aside>
            </div>
        ))
        // <div className="col-xl-4 col-lg-5 col-md-12 sticky-top p-b30">
        //     <aside className="side-bar listing-side-bar">
        //         <div className="widget widget_map">
        //             <img src={"https://eazymovement.com"+buss} alt="business " className="m-b30 align-self-stretch" style={{ width: '100%', height: '400px'}} />
        //             <a href="https://www.google.com/maps/search/?api=1&query=6.3549195,5.6430018" target="_blank" rel="noreferrer" className="site-button button-lg radius-xl m-b30 text-uppercase"><i className="la la-map"></i> get directions</a>
        //         </div>
        //     </aside>
        // </div>
    )
}

export default MapInfo