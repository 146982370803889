import React from 'react'

const Places = () => {
    return (
        <div className="section-full bg-white content-inner">
            <div className="container">
                <div className="section-head text-black text-center">
                    
                    <h2 className="box-title">Popular Places</h2>
                    <div className="dlab-separator bg-primary"></div>
                    <p>Here are the list of most popular places in your current location</p>
                </div>
                
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="featured-bx m-b30">
                            <a href="/list_details/56">
                                <div className="featured-media">
                                    <img src="https://eazylocate.com/images/IMG_20210202_134701_894 - victoria italume.jpg" alt="" style={{ height: "340px"}} />
                                </div>
                            </a>
                            <div className="featured-info">
                                <ul className="featured-star">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                </ul>
                                <h5 className="title"><a href="/list_details/56">Kada Cinema</a></h5>
                                <ul className="featured-category">
                                    <li><i className="fa fa-moon-o"></i> 18 Hours</li>
                                    <li><i className="fa fa-map-o"></i> 30+ Searches</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="featured-bx m-b30">
                            <a href="/list_details/10004">
                                <div className="featured-media">
                                    <img src="https://eazylocate.com/images/IMG_20220126_163308_2_1643212212196.jpg" alt="" style={{ height: "340px"}} />
                                </div>
                            </a>
                            <div className="featured-info">
                                <ul className="featured-star">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                </ul>
                                <h5 className="title"><a href="/list_details/10004">Reign Restaurant</a></h5>
                                <ul className="featured-category">
                                    <li><i className="fa fa-moon-o"></i> 15 Hours</li>
                                    <li><i className="fa fa-map-o"></i> 26+ Searches</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="featured-bx m-b30">
                            <a href="/list_details/2859">
                                <div className="featured-media"><img src="https://eazylocate.com/images/IMG_20210429_130806_344_1619698362834.jpg" alt="" style={{ height: "340px"}} />
                            </div>
                            </a>
                            <div className="featured-info">
                                <ul className="featured-star">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                </ul>
                                <h5 className="title"><a href="/list_details/2859">The Quid</a></h5>
                                <ul className="featured-category">
                                    <li><i className="fa fa-moon-o"></i> 13 Hours</li>
                                    <li><i className="fa fa-map-o"></i> 15+ Searches</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="featured-bx m-b30">
                            <a href="/list_details/504">
                                <div className="featured-media">
                                    <img src="https://eazylocate.com/images/IMG_20210219_105051_901_1613728630908.jpg" alt="" style={{ height: "340px"}} />
                                </div>
                            </a>
                            <div className="featured-info">
                                <ul className="featured-star">
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                    <li><i className="fa fa-star"></i></li>
                                </ul>
                                <h5 className="title"><a href="/list_details/504">Club Joker</a></h5>
                                <ul className="featured-category">
                                    <li><i className="fa fa-moon-o"></i> 20 Hours</li>
                                    <li><i className="fa fa-map-o"></i> 31 Searches</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Places