import React from 'react'

const Banner = () => {
  return (
    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-black-middle" style={{ backgroundImage: `url(../assets/images/banner/bnr3.jpg)`, height: '30px'}}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Search Result</h1>
                <p>Find awesome places, bars, restaurants &amp; activities.</p>
            </div>
        </div>
    </div>
  )
}

export default Banner