import React from 'react'
import Banner from '../Banner'
import ContentArea from './ContentArea'

const Content = () => {
    return (
        <div className="page-content bg-white">
            <Banner />
            <ContentArea />
        </div>
    )
}

export default Content