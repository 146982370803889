import React from 'react'
import Content from '../components/category/Content'
import { useParams } from 'react-router-dom'

const Category = () => {
  let param = useParams();
  return (
    <div>
        <Content param={param} />
    </div>
  )
}

export default Category