
const ListBox = ({businesses}) => {
    return (
        <div className="container">
            <div className="row">
                {
                     businesses.map((business)=>(
                        <div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={business.id}>
                            <div className="listing-bx overlap" style={{height:'230px'}}>
                                
                                <div className="listing-media">
                                    <img src={"https://eazylocate.com/"+business.image} alt="" />
                                </div>
                                <div className="listing-info">
                                    <h5>
                                        <a href={"/list_details/"+business.id} style={{color:"#fff"}}>{ business.business_name }</a>
                                    </h5>
                                    
                                    <span className='badge badge-warning p-2'>{ "("+business.distance+" - km Away)" }</span>
                                </div>
                                <ul className="wish-bx">
                                   
                                    <li>
                                        <a className="info-btn" href={ "https://www.google.com/maps/search/?api=1&query="+business.geocode }>
                                            <i className="fa fa-map-marker"></i>
                                        </a>
                                    </li>									
                                    <li>
                                        <a className="link-btn" href={ "tel:"+business.phone }>
                                            <i className="fa fa-phone"></i>
                                        </a>
                                    </li>									
                                </ul>
                                
                            </div>
                        </div>
                     ))
                }
               
            </div>
        </div>
    )
}

export default ListBox