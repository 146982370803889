import React from 'react'

const Instruction = () => {
    return (
        <div className="section-full bg-img-fix most-visited content-inner overlay-primary-dark" style={{backgroundImage:`url(assets/images/background/bg1.jpg)`}}>
            <div className="container">
                <div className="section-head text-white text-center">
                    <h2 className="box-title">How It Works?</h2>
                    <div className="dlab-separator bg-white"></div>
                    <p>Here is a short description of how the application works, following each step and making sure you get effectiveness of the application.</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="icon-bx-wraper sr-box center box1 m-b30">
                            <div className="icon-bx-lg radius bg-white m-b20"><a href="/" className="icon-cell text-primary"><i className="ti-search"></i></a> </div>
                            <div className="icon-content">
                                <h3 className="dlab-tilte">Search for what you want?</h3>
                                <p>You first search for the location or places or item that you need using the search bar with the location.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="icon-bx-wraper sr-box center  m-b30">
                            <div className="icon-bx-lg radius bg-white m-b20"><a href="/" className="icon-cell text-primary"><i className="ti-gift"></i></a> </div>
                            <div className="icon-content">
                                <h3 className="dlab-tilte">Find What You Want?</h3>
                                <p>You find a list of result from your search, giving you result of the different variation to choose from.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="icon-bx-wraper sr-box center box1 m-b30">
                            <div className="icon-bx-lg radius bg-white m-b20"><a href="/" className="icon-cell text-primary"><i className="ti-rocket"></i></a> </div>
                            <div className="icon-content">
                                <h3 className="dlab-tilte">Explore Amazing Places</h3>
                                <p>Locate and explore your desired location by transport route description if using public transport or by clicking directions to give map direction if you want to walk, ride a bicycle/motorcycle or drive. You can also simply put a phone call across if you want to make reservations or book an appointment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Instruction