import React from 'react'
import { Link } from 'react-router-dom'

const CategoryDiv = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <Link to="/category/Law Courts Financial, Insurance">
                                        <img src="assets/images/blog/grid/lagal_finance.png" alt="" />
                                    </Link>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag">
                                        <a href="/category/Law Courts, Financial, Insurance">Legal & Financial</a>
                                        <span className="badge badge-pill badge-warning p-2 ml-2 text-white">500+</span>
                                    </div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/Law Courts Financial, Insurance">Law firm, Courts, Financial investment, Insurance company.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <a href="/category/mechanic electronics repair">
                                        <img src="assets/images/blog/grid/auto_electronics.png" alt="" />
                                    </a>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag"><a href="/category/mechanic electronics repair">Auto and Electronics</a><span className="badge badge-pill badge-warning p-2 ml-2 text-white">1,000+</span></div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/mechanic electronics repair">Mechanic workshops, Electrical Workshops, Electronics repair.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <a href="/category/cinema, Event Halls, art gallery">
                                        <img src="assets/images/blog/grid/entertainment.png" alt="" />
                                    </a>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag"><a href="/category/cinema, Event Halls, art gallery">Entertainment</a><span className="badge badge-pill badge-warning p-2 ml-2 text-white">2,000+</span></div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/cinema, Event Halls, art gallery">Cinema and Event Halls, Art Gallery, Night clubs and Sit outs.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <a href="/category/travels visa spar massage">
                                        <img src="assets/images/blog/grid/travel_spar.png" alt="" />
                                    </a>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag">
                                        <a href="/category/travels visa spar massage">Travel/Spar</a>
                                        <span className="badge badge-pill badge-warning p-2 ml-2 text-white">1,500+</span>
                                    </div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/travels visa spar massage">Travel agents, visa processing, spar/massage shops.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <Link to="/category/Hospitals, Pharmacy, herbal">
                                        <img src="assets/images/blog/grid/healt_medicine.png" alt="" />
                                    </Link>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag">
                                        <a href="/category/Hospitals, Pharmacy, herbal">Health & Medicine</a>
                                        <span className="badge badge-pill badge-warning p-2 ml-2 text-white">1,000+</span>
                                    </div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/Hospitals, Pharmacy, herbal">Hospitals, Pharmacy, chemist and local herbal shops.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="owl-item">
                        <div className="item">
                            <div className="blog-post dez-blog">
                                <div className="dlab-post-media">
                                    <a href="/category/business">
                                        <img src="assets/images/blog/grid/business_supplies.png" alt="" />
                                    </a>
                                </div>
                                <div className="dlab-info">
                                    <div className="category-tag">
                                        <a href="/category/business">Business &amp; supplies</a>
                                        <span className="badge badge-pill badge-warning p-2 ml-2 text-white">15,000+</span>
                                    </div>
                                    <div className="dlab-post-title">
                                        <h5 className="post-title">
                                            <a href="/category/business">Everyday business, Building materials supply and others.</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default CategoryDiv