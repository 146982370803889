


const SearchResult = ({bixs}) => {

    return (
        <div className="col-lg-8 col-md-6">
            <div className="row">
                {
                    bixs.map((bix)=>(
                        <div className="col-lg-6 col-md-12 col-sm-6 m-b30" key={bix.id}>
                            <div className="listing-bx overlap">
                                <div className="listing-media">
                                    <img src={"https://eazylocate.com/"+bix.image} alt={bix.business_name} style={{height:'240px'}} />
                                </div>
                                <div className="listing-info">
                                    <ul className="featured-star">
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        
                                    </ul>
                                    <h5>
                                        <a style={{ color:"#fff" }} href={"/list_details/"+bix.id}>{ bix.business_name  }</a>
                                    </h5>
                                    <span className="badge badge-warning p-2">{ "("+bix.distance+"km Away)" }</span>
                                </div>
                                <ul className="wish-bx">
                                    <li><a className="info-btn" href={"https://www.google.com/maps/search/?api=1&query="+bix.geocode} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i></a></li>									
                                    <li><a className="link-btn" href={"tel:"+bix.phone}><i className="fa fa-phone"></i></a></li>									
                                </ul>
                            </div>
                        </div>
                    ))  
                }
            </div>
        </div>

    )
}

export default SearchResult