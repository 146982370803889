import React from 'react'
import ContactDetails from './ContactDetails'
import ContactForm from './ContactForm'

const ContentArea = () => {
    return (
        <div className="section-full content-inner">
            <div className="container">
                <ContactDetails />
                <ContactForm />
            </div>
        </div>
    )
}

export default ContentArea