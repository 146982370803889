import React from 'react'
import CategoryDiv from './CategoryDiv'

const ContentArea = () => {
    return (
        <div className="content-block">
		    <div className="section-full content-inner bg-white">
                <CategoryDiv />
            </div>
        </div>
    )
}

export default ContentArea