import React from 'react'

const Whatsapp = () => {
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 m-b30" >
            <div className="icon-bx-wraper p-lr20 p-tb50 center seth contact-bx" >
                <div className="icon-bx-sm radius m-b20 bg-primary m-b20">
                    <a href="/" className="fa fa-comments fa-2xl"><i className="ti-chat"></i></a>
                </div>
                <div className="icon-content">
                    <h5 className="dlab-tilte text-uppercase">Whatsapp</h5>
                    <p>
                        +234 915 3187 543 <br />
                        234 803 4725 279
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Whatsapp