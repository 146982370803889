import React from 'react'
import Email from './Email'
import Phone from './Phone'
import Whatsapp from './Whatsapp'

const ContactDetails = () => {
  return (
    <div className="row dzseth m-b50">
        <Email />
        <Phone />
        <Whatsapp/>
    </div>
  )
}

export default ContactDetails