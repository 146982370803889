import React from 'react'
import Content from '../components/listDetails/Content'

const ListDetails  = () => {
  return (
    <div>
      <Content />
    </div>
  )
}

export default ListDetails 