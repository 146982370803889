import React from 'react'

const Banner = () => {
  return (
    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-black-middle" style={{backgroundImage:`url(../assets/images/banner/bnr3.jpg)`}}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Business Listing</h1>
                <p>Find great places, bars, restaurants & activities.</p>
                
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li><a href="/">Home</a></li>
                        <li>Listings</li>
                    </ul>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Banner