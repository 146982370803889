import React from 'react'
import { useState } from 'react'

import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const SearchBar = () => {

    const param = useParams()
    const keywords = param.keywords
    
    const [keyword, setKeyword] = useState(keywords)
    const [area, setArea] = useState("")



    const naviate = useNavigate()
    const handleSearch = () => {
		naviate("/search/"+keyword)
	}

    return (
        <div className="col-lg-4 col-md-6">
            <div className="sticky-top">
                <div className="listing-filter-sidebar">
                    <h4 className="title m-b30">Filter By</h4>
                    <form>
                        <div className="form-group">
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="What are your looking for?" 
                                    value={keyword} 
                                    onChange={
                                        (e)=>setKeyword(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Your location" 
                                    value={area} 
                                    onChange={
                                        (e)=>setArea(e.target.value)
                                    } 
                                />									
                            </div>
                        </div>
                    </form>
                </div>
                <div className="listing-filter-sidebar">
                    <button className="site-button radius-xl m-l10 btn-block" onClick={handleSearch}>Search</button>
                </div>
                
            </div>
        </div>
    )
}

export default SearchBar