import React from 'react'
import {  useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Banner = () => {

	const [keyword, setKeyword] = useState("")
	const [area, setArea] 		= useState("")

	const naviate = useNavigate()

	const handleSearch = () => {
		naviate("/search/"+keyword)
	}
  	return (
    	<div className="dlab-bnr-inr dlab-bnr-inr-md" style={{backgroundImage:`url(assets/images/main-slider/slide1.jpg)`, backgroundSize: 'cover'}}>
            <div className="container">
                <div className="dlab-bnr-inr-entry align-m dlab-home">
					<div className="bnr-content">
						<h2><strong>Find the Best search results</strong></h2>
					</div>
					<div className="dlab-tabs search-filter">
						<p className="text-center" style={{color:'#fff'}}>All top locations – from restaurants, clubs, to galleries, artisans, services, goods, exotics places and more.</p>
						<div className="tab-content">
							<div id="place" className="tab-pane active">
								<form>
									<div className="input-group">
										<input type="text" className="form-control" placeholder="What are you looking for?" value={keyword} onChange={(e)=>{setKeyword(e.target.value)}} />
										<input type="text" className="form-control" placeholder="Benin City" value={area} onChange={(e)=>{setArea(e.target.value)}} />
										<div className="input-group-prepend">
											<button className="site-button text-uppercase" onClick={handleSearch}><i className="fa m-r5 fa-search"></i> Search</button>
										</div>
									</div>
								</form>
							</div>
							
						</div>
						<p className="text-center text-white m-b10 m-t30">Find awesome places, bars, hotels, supermarkets, schools, agencies, saloons, SPA, religious centers, govt parastatals & activities.</p>
					</div>
					
					<div className="category-bx">
						<a href="/category/market" className="category">
							<i className="flaticon-cart-of-ecommerce" style={{color: "rgb(245, 84, 142)"}}></i>
							<p>carts</p>
						</a>
						<a href="/category/food" className="category">
							<i className="flaticon-carrot" style={{color: "rgb(50, 204, 111)"}}></i>
							<p>foods</p>
						</a>
						<a href="/category/bank" className="category">
							<i className="flaticon-money" style={{color: "rgb(253, 126, 20)"}}></i>
							<p>Banks</p>
						</a>
						<a href="/category/medical" className="category">
							<i className="flaticon-pill-capsule" style={{ color: "rgb(245, 84, 142)" }}></i>
							<p>medicine</p>
						</a>
						<a href="/category/pastry" className="category">
							<i className="flaticon-birthday-cake" style={{ color: "rgb(23, 162, 184)" }}></i>
							<p>cake</p>
						</a>
						<a href="/category/park" className="category">
							<i className="flaticon-place" style={{ color: "rgb(144, 58, 249)" }}></i>
							<p>place</p>
						</a>
					</div>
				</div>
            </div>
        </div>
  	)
}

export default Banner