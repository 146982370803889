
const Info = ( {business, id} ) => {
        
    

    return (
        business.map(buss=>(
            <div className="col-xl-8 col-lg-7 col-md-12 p-b30" key={id}>
                <aside className="side-bar listing-side-bar">
                    <div className="content-box">
                        <div className="content-header">
                            <h3 className="title"> {buss.business_name}</h3>
                        </div>
                        
                        <div className="content-body">
                            <ul className="icon-box-list">
                                <li>
                                    <div className="icon-cell bg-gray">
                                        <i className="la la-certificate"></i>
                                    </div>
                                    <span>{buss.business_type}</span>
                                </li>
                                {
                                    buss.website && 
                                    <li>
                                        <a href="https://" className="icon-box-info">
                                            <div className="icon-cell bg-gray">
                                                <i className="la la-globe"></i>
                                            </div>
                                            <span>{buss.website}</span>
                                        </a>
                                    </li>
                                    
                                }
                                
                                {
                                    buss.email &&
                                    <li>
                                        <a href="mailto:nil@yahoo.com" className="icon-box-info">
                                            <div className="icon-cell bg-gray">
                                                <i className="la la-envelope"></i>
                                            </div>
                                        </a><span>{buss.email}</span>
                                    </li>
                                }
                                
                                <li>
                                    <a href="tel:" className="icon-box-info">
                                        <div className="icon-cell bg-gray">
                                            <i className="la la-phone"></i>
                                        </div>
                                    </a>
                                    <span>{buss.phone}</span>
                                </li>
                                <li>
                                    <a href="/link" className="icon-box-info">
                                        <div className="icon-cell bg-gray">
                                            <i className="la la-map-marker"></i>
                                        </div>
                                    </a>
                                    <span>{buss.address}</span>
                                </li>
                                {
                                    buss.route && 
                                    <li>
                                        <a href="/link" className="icon-box-info">
                                            <div className="icon-cell bg-gray">
                                                <i className="la la-bus"></i>
                                            </div>
                                        </a>
                                        <span> {buss.route}</span>
                                    </li>
                                }
                               
                                <li>
                                    <a href="https://www.google.com/maps/dir/?api=1&amp;query=6.3549195,5.6430018" target="_blank" rel="noreferrer" className="icon-box-info">
                                        <div className="icon-cell bg-gray">
                                            <i className="la la-newspaper-o"></i>
                                        </div>
                                        <span>{ buss.service }</span>
                                        </a>
                                    </li>
                            </ul>
                            <ul className="list-inline m-tb20">
                                <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-twitter"></i></a></li>
                            </ul>
                            <a href={"mailto:"+buss.email} className="site-button outline outline-1 button-md col-md-6">
                                <i className="la la-envelope"></i> <span>Send Mail</span>
                            </a>
                            <a href={"tel:"+buss.phone} className="site-button outline outline-1 button-md col-md-6">
                                <i className="la la-phone"></i> <span>Make a Call </span>
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        ))
            // <div className="col-xl-8 col-lg-7 col-md-12 p-b30" >
            //     <aside className="side-bar listing-side-bar">
            //         <div className="content-box">
            //             <div className="content-header">
            //                 <h3 className="title"> {buss.business_name}</h3>
            //             </div>
                        
            //             <div className="content-body">
            //                 <ul className="icon-box-list">
            //                     <li>
            //                         <div className="icon-cell bg-gray">
            //                             <i className="la la-certificate"></i>
            //                         </div>
            //                         <span>{buss.business_type}</span>
            //                     </li>
            //                     <li>
            //                         <a href="https://" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-globe"></i>
            //                             </div>
            //                             <span>{buss.website}</span>
            //                         </a>
                                    
            //                         </li>
            //                     <li>
            //                         <a href="mailto:nil@yahoo.com" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-envelope"></i>
            //                             </div>
            //                         </a><span>{buss.email}</span>
            //                     </li>
            //                     <li>
            //                         <a href="tel:" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-phone"></i>
            //                             </div>
            //                         </a>
            //                         <span>{buss.phone}</span>
            //                     </li>
            //                     <li>
            //                         <a href="/link" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-map-marker"></i>
            //                             </div>
            //                         </a>
            //                         <span>{buss.address}</span>
            //                     </li>
            //                     <li>
            //                         <a href="/link" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-bus"></i>
            //                             </div>
            //                         </a>
            //                         <span>Fromake a bus to ramat park, stop at </span>
            //                     </li>
            //                     <li>
            //                         <a href="https://www.google.com/maps/dir/?api=1&amp;query=6.3549195,5.6430018" target="_blank" rel="noreferrer" className="icon-box-info">
            //                             <div className="icon-cell bg-gray">
            //                                 <i className="la la-newspaper-o"></i>
            //                             </div>
            //                             <span>Hotel, club and bar</span>
            //                             </a>
            //                         </li>
            //                 </ul>
            //                 <ul className="list-inline m-tb20">
            //                     <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-facebook"></i></a></li>
            //                     <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-google-plus"></i></a></li>
            //                     <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-linkedin"></i></a></li>
            //                     <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-instagram"></i></a></li>
            //                     <li><a href="/link" className="site-button radius-no sharp"><i className="fa fa-twitter"></i></a></li>
            //                 </ul>
            //                 <a href="mailto:nil@yahoo.com" className="site-button outline outline-1 button-md col-md-6">
            //                     <i className="la la-envelope"></i> <span>Send Mail</span>
            //                 </a>
            //                 <a href="tel:" className="site-button outline outline-1 button-md col-md-6">
            //                     <i className="la la-phone"></i> <span>Make a Call </span>
            //                 </a>
            //             </div>
            //         </div>
            //     </aside>
            // </div>
        
        
    )
}

export default Info