import React from "react";
import Banner from "../Banner";
import ContentArea from "./ContentArea";

const Content = () => {
    return (
        <div className="page-content bg-white">
            <Banner />
            <div className="section-full content-inner">
                <div className="container">
                    <ContentArea />
                </div>
            </div>
        </div>
    );
};

export default Content;
