import React from 'react'
import ListBox from './ListBox'

import { useState, useEffect } from 'react'

const ContentArea = ({param}) => {

    let keyword = param.category;

    const [businesses, setBusinesses]   = useState(null)
    const [isPending, setIsPending]     = useState(true)
    const [offset, setOffset]           = useState(0)

    const [myLat, setMyLat] = useState(null)
    const [myLong, setMyLong] = useState(null)

    
    useEffect(()=>{
        const showPosition = () => {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    setMyLat(position.coords.latitude)
                    setMyLong(position.coords.longitude)
                });
            } else {
                alert("Sorry, your browser does not support HTML5 geolocation.");
            }
        }

        showPosition()

        

        fetch(`https://mob.eazylocate.com/api/business/search.php?keyword=${keyword}&offset=${offset}&lat=${myLat}&log=${myLong}`)
        .then((res)=>{
            return res.json();
        })
        .then((data)=>{
            const sorted = data.location.sort((a,b)=>a.distance-b.distance)
            const chunk = sorted.slice(offset, offset + 12);
            setBusinesses(chunk)
            setIsPending(false)
        })
    },[keyword, offset, myLat, myLong])

    const nextFun = (e) =>{
        setOffset(offset+12)
    }

    const prevFun = (e) =>[
        setOffset(offset-12)
    ]
    
    return (
        <div className="content-block">
            <div className="section-full content-inner bg-white">
                {
                    isPending && 
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                            <div className="mx-auto my-auto p-5" >
                                <img src="../assets/images/loading.gif" alt="Loading" />
                            </div>
                        </div>
                    </div>
                }
                {businesses && <ListBox businesses={businesses} />}
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <button className="site-button btn-lg" onClick={ prevFun }><i className="fa fa-arrow-left"> Prev</i></button>
                        <button className="site-button ml-auto" onClick={ nextFun }>Next - <i className="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentArea