import React from 'react'
import Banner from './Banner'
import ContentArea from './ContentArea'

import { useParams } from 'react-router-dom'

const Content = () => {
  let param = useParams()

  return (
        <div className="page-content bg-white">
            <Banner />
            <ContentArea id={param.id}/>
        </div>
  )
}

export default Content