import React from 'react'
import Content from '../components/addList/Content'

const AddList = () => {
  return (
    <div>
      <Content />
    </div>
  )
}

export default AddList