import React from 'react'
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'

const ContentArea = ({bixs}) => {
  return (
    <div className="content-block">
        <div className="section-full content-inner bg-white">
            <div className="container">
                <div className="row">
                    <SearchBar />
                    <SearchResult bixs ={bixs} />
                </div>
            </div>
        </div>
    </div>
)
}

export default ContentArea