import React from 'react'
import Content from '../components/search/Content'

const Search = () => {
  return (
    <div>
        <Content />
    </div>
  )
}

export default Search