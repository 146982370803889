import React from 'react'

const Header = () => {
  return (
    <header className="site-header header-transparent mo-left">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix ">
                <div className="container clearfix">
                    <div className="logo-header mostion">
						<a href="/" className="logo-1">
                            <img src="../assets/images/logo-white-1.png" alt="" />
                        </a>
						<a href="/" className="logo-2">
                            <img src="assets/images/logo-black-1.png" alt="" />
                        </a>
					</div>
                    <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
                    <div className="extra-nav">
                        <div className="extra-cell">
							<a href="/add_list" className="site-button radius-xl m-l10"><i className="fa fa-plus m-r5"></i> Add Entry</a>
                        </div>
                    </div>
                    <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="nav navbar-nav">              
							<li className="active">
								<a href="/">Home</a>
							</li>
							<li>
								<a href="/listing">Listings</a>
							</li>
							<li className="">
								<a href="/categories">Categories</a>
							</li>
							<li><a href="/contact">Contact Us</a></li>
						</ul>		
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header