import React from 'react'
import ListBox from './ListBox'

import { useState, useEffect } from 'react'

const ContentArea = () => {
    const [businesses, setBusinesses]   = useState(null)
    const [isPending, setIsPending]     = useState(true)
    const [offset, setOffset]           = useState(0)

    const [myLat, setMyLat] = useState(null)
    const [myLong, setMyLong] = useState(null)
   
    const showPosition = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                setMyLat(position.coords.latitude)
                setMyLong(position.coords.longitude)

                console.log(myLat)
                console.log(myLong)
            });
        } else {
            console.log("Sorry, your browser does not support HTML5 geolocation.");
        }
    }
    showPosition()
    

    useEffect(()=>{
        
        
        fetch(`https://mob.eazylocate.com/api/business/search.php?keyword=bank&offset=${offset}&lat=${myLat}&log=${myLong}`)
        .then((res)=>{
            return res.json();
        })
        .then((data)=>{
            const sorted = data.location.sort((a,b)=>a.distance-b.distance)
            const chunk = sorted.slice(offset, offset + 12);
            setBusinesses(chunk)
            setIsPending(false)
        })

    },[offset, myLat, myLong])

    const nextFun = (e) =>{
        setOffset(offset+12)
    }

    const prevFun = (e) =>{
        setOffset(offset-12)
    }
    
    return (
        <div className="content-block">
            <div className="section-full content-inner bg-white">
                {
                    isPending &&
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                            <div className="mx-auto my-auto p-2" >
                                <img src="../assets/images/loading.gif" alt="loading" />
                            </div>
                        </div>
                    </div>
                }
                {businesses && <ListBox businesses={businesses} />}
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <button className="site-button btn-lg" onClick={ prevFun }><i className="fa fa-arrow-left"> Prev</i></button>
                        <button className="site-button ml-auto" onClick={ nextFun }>Next up <i className="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentArea