import React from 'react'
import ContentArea from './ContentArea'
import Banner from '../Banner'

const Content = ({param}) => {

    return (
        <div className="page-content bg-white">
            <Banner />
            <ContentArea param={param} />
        </div>     
    )
}

export default Content