// import axios from "axios";
import React from "react";
import { useState } from "react";

const FormSection = () => {
    const [name, setName]   = useState("")
    const [biztype, setBiztype]     = useState("")
    const [email, setEmail]         = useState("")
    const [phone, setPhone]         = useState("")
    const [address, setAddress]     = useState("")
    const [route, setRoute]         = useState("")
    const [service, setService]     = useState("")
    const [media, setMedia]         = useState("")

    const handleSubmit = async (e) =>{
        e.preventDefault()
        try {
            // const option = {
            //     headers:{
            //         "Content-Type":"application/json"
            //     }
            // }

            const body ={
                name: name,
                biztype: biztype,
                email: email,
                phone: phone,
                address: address,
                route: route,
                service: service,
                media: media
            }

            // const res = await axios("/address", body, option);
            console.l0og(body)        } catch (err) {
            console.error(err)
        }
    }
    return (
        <div className="col-xl-8 col-lg-7 col-md-12 m-b30">
            <form className="add-listing-form" onSubmit={handleSubmit}>
                <div className="content-box">
                    <div className="content-header">
                        <h3 className="title">
                        PRIMARY LISTING DETAILS
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Primary Listing Details"
                            className="tooltip-bx fa fa-question-circle"
                        ></i>
                        </h3>
                    </div>
                    <div className="content-body">
                        <div className="form-group">
                        <label>Business Name</label>
                        <input
                            type="text"
                            className="form-control m-b10"
                            placeholder="Staple &amp; Fancy Hotel"
                            required=""
                            value={name}
                            onChange={e=>setName(e.target.value)}
                        />
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">
                        <h3 className="title">
                        CATEGORY &amp; SERVICES
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Category &amp; Services"
                            className="tooltip-bx fa fa-question-circle"
                        ></i>
                        </h3>
                    </div>
                    <div className="content-body">
                        <div className="form-group">
                        <label>Type of Business </label>
                        <input
                            type="text"
                            className="form-control"
                            required=""
                            value={biztype}
                            onChange={
                                (e)=>setBiztype(e.target.value)
                            }
                        />
                        </div>
                    </div>
                </div>
                <div className="content-box">
                
                    <div className="content-header">
                        <h3 className="title">
                        Contact
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Social Media"
                            className="tooltip-bx fa fa-question-circle"
                        ></i>
                        </h3>
                    </div>

                    <div className="content-body">
                        <div className="add-social-link social-btn-container">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                required=""
                                value={email}
                                onChange={
                                    (e)=>setEmail(e.target.value)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Mobile Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phone"
                                placeholder="Phone"
                                required=""
                                value={phone}
                                onChange={
                                    (e)=>setPhone(e.target.value)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder="Address"
                                required=""
                                value={address}
                                onChange={
                                    (e)=>setAddress(e.target.value)
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="route">Route</label>
                            <input
                                type="text"
                                className="form-control"
                                id="route"
                                placeholder="Route"
                                required=""
                                value={route}
                                onChange={
                                    (e)=>setRoute(e.target.value)
                                }
                            />
                        </div>
                        </div>
                    </div>

                </div>
                <div className="content-box">
                    <div className="content-header">
                        <h3 className="title">
                        MORE INFO
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="More Info"
                            className="tooltip-bx fa fa-question-circle"
                        ></i>
                        </h3>
                    </div>

                    <div className="content-body editor">
                        <div className="form-group">
                        <label>Services Rendered(Separated with comma)</label>
                        <textarea
                            className="form-control"
                            id="input-tags"
                            value={service}
                            rows="2"
                            onChange={
                                (e)=>setService(e.target.value)
                            }
                        ></textarea>
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">
                        <h3 className="title">
                        MEDIA
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Social Media"
                            className="tooltip-bx fa fa-question-circle"
                        ></i>
                        </h3>
                    </div>
                    <div className="content-body">
                        <div className="form-group">
                        <label>Upload Business Logo</label>
                        <input type="file" accept="image/*" 
                            value={media}
                            onChange={
                                (e)=>setMedia(e.target.value)
                            }    
                        />
                        </div>
                    </div>
                </div>
                <button className="site-button btn-block button-md" type="submit">
                Submit Listing
                </button>
            </form>
        </div>
    );
};

export default FormSection;