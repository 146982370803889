import React from 'react'
import ContentArea from './ContentArea'
import Banner from '../Banner'

const Content = () => {
    return (
        <div className="page-content bg-white">
            <Banner />
            <ContentArea />
        </div>     
    )
}

export default Content