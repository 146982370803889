import React from 'react'
import Info from './Info'
import MapInfo from './MapInfo'

import { useState, useEffect } from 'react'

const ContentArea = ({id}) => {

    const [business, setBusiness]   = useState(null)

    useEffect(()=>{
        fetch(`https://mob.eazylocate.com/api/business/read_single.php?id=${id}`)
        .then((response)=>{
            return response.json()
        })
        .then((data)=>{
            setBusiness(data.location)
        })
    }, [id])

    return (
        <div className="section-full content-inner">
            <div className="container">
                <div className="row">
                    {business && <Info business={business}  id={id}/> }
                    {business && <MapInfo business={business} id={id} /> }
                </div>
            </div>
        </div>
    )
}

export default ContentArea