import React from 'react'
import FormSection from './FormSection'
import ImageSection from './ImageSection'

const ContentArea = () => {
    return (
        <div className="section-ful content-inner">
            <div className="container">
                <div className="row">
                   <FormSection />
                   <ImageSection/>
                </div>
            </div>
        </div>
    )
}

export default ContentArea