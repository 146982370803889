import React from 'react'

const Email = () => {
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 m-b30" >
            <div className="icon-bx-wraper p-lr20 p-tb50 center seth contact-bx" >
                <div className="icon-bx-sm radius m-b20 bg-primary m-b20">
                    <a href="info@eazymovement.com" className="icon-cell"><i className="ti-email"></i></a>
                </div>
                <div className="icon-content">
                    <h5 className="dlab-tilte text-uppercase">Email</h5>
                    <p>
                        info@eazymovement.com <br />
                        admin@eazymovement.com
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Email