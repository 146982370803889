import React from "react";

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12">
                            <div className="widget">
                                <img
                                    src="images/logo-white.png"
                                    className="m-b15"
                                    alt=""
                                    width="180"
                                />
                                <p className="text-capitalize m-b20">
                                    Eazy Movement Is A Web And Mobile App That
                                    Helps You Search And Find Places, Schools,
                                    Bars, Restaurant, Stores, Businesses,
                                    Services, Goods, Hotels And More In The City
                                    With Ease And Also Helps You Explore The
                                    City.
                                </p>

                                <ul className="list-inline m-a0">
                                    <li>
                                        <a
                                            href="https://facebook.com/eazymovementofficial"
                                            className="site-button facebook circle "
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://wa.me/2349153187543"
                                            className="site-button whatsapp circle "
                                        >
                                            <i className="fa fa-whatsapp"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://instagram.com/eazymovementofficial"
                                            className="site-button instagram circle "
                                        >
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://instagram.com/eazymovementofficial"
                                            className="site-button twitter circle "
                                        >
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5 col-md-7 col-sm-12 col-12">
                            <div className="widget border-0">
                                <h5 className="m-b30 text-white">
                                    Frequently Asked Questions
                                </h5>
                                <ul className="list-2 list-line">
                                    <li>
                                        <a href="/category/hotels">Hotel</a>
                                    </li>
                                    <li>
                                        <a href="/category/restaurant">
                                            Restaurant
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/apartment">
                                            Apartment
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/spa">
                                            SPA &amp; Beauty
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/automation">
                                            Automation
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/travel">Travel</a>
                                    </li>
                                    <li>
                                        <a href="/category/furniture">
                                            Furniture Works
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/boutique">
                                            Boutique
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/fitness">
                                            Fitness Center
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/photography">
                                            Photography
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/cosmetics">
                                            Cosmetics
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/petrol station">
                                            Petrol Station
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-7 col-sm-12 col-12">
                            <div className="widget border-0">
                                <h5 className="m-b30 text-white">
                                    Frequently Asked Questions
                                </h5>
                                <ul className="list-2 list-line">
                                    <li>
                                        <a href="/category/courier">Courier</a>
                                    </li>
                                    <li>
                                        <a href="/category/police statioin">
                                            Police Station
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/tutorial">
                                            Tutorial Center
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/solar panel">
                                            Solar Panel
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/Tire">Tyre Depot</a>
                                    </li>
                                    <li>
                                        <a href="/category/mama put">
                                            Mama Put
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/art">
                                            Art &amp; Design
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/salon">Hair salon</a>
                                    </li>
                                    <li>
                                        <a href="/category/granite">
                                            Granite Depot
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/livestock">
                                            Livestock Feed
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/Laboratory">
                                            Laboratory
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category/teeth">
                                            Dental Care
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-md-4">
                            <a href="/privacy_policy">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <span className="fbottom-like">
                                &copy; 2023 Eazy Movement{" "}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
