import React from 'react'
import PageContent from '../components/categories/PageContent'

const Categories = () => {
  return (
    <div>
        <PageContent />
    </div>
  )
}

export default Categories